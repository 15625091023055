<template>
  <div class="p-5 text-center">
    <!-- eslint-disable -->
    <h5
      class="d-flex flex-wrap align-items-center justify-content-center text-center mb-3 highlightOrders"
      v-html="$tc('message.orders_pending_warning', placedOrders)"
    />
    <!-- eslint-enable -->

    <button
      type="button"
      class="btn btn-light btn-block"
      @click.prevent="testSoundAndClose"
    >
      OK
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { propOr } from "ramda";
import bus from "@/utils/events/bus";

export default {
  computed: {
    ...mapGetters({
      ordersCount: "liveOrders/getOrdersByStatusCount",
    }),
    placedOrders() {
      return propOr(0, "placed", this.ordersCount);
    },
  },
  methods: {
    testSoundAndClose() {
      bus.$emit("testSound");
      this.$emit("close");
    },
  },
};
</script>

<style>
.highlightOrders strong {
  display: inline-block;
  min-width: 25px;
  font-size: 13px;
  color: #fff;
  line-height: 1;
  white-space: nowrap;
  margin: 6px;
  padding: 6px;
  border-radius: 30px;
  background-color: #fc544b;
}
</style>
