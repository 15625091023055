<template>
  <div class="liveOrders__filters">
    <label
      v-for="(value, key) in statusAvailable"
      :key="key"
      class="liveOrders__filterItem"
      :class="{
        [key]: true,
        'liveOrders__filterItem--active': key === status,
      }"
    >
      {{ value }}
      <small v-if="key !== 'all'" class="liveOrders__filterItem--count">
        {{ statusCount[key] || 0 }}
      </small>
      <small v-else class="liveOrders__filterItem--count">
        {{ ordersCount || 0 }}
      </small>
      <input v-model="status" type="radio" :value="key" />
    </label>
  </div>
</template>

<script>
import { sum, isEmpty, values } from "ramda";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    status: "all",
    statusAvailable: {
      all: "All",
      placed: "New",
      accepted: "In Kitchen",
      ready: "In Route/Ready",
      scheduled: "Scheduled",
    },
  }),
  computed: {
    ...mapGetters({
      statusCount: "liveOrders/getOrdersByStatusCount",
    }),
    ordersCount() {
      if (isEmpty(this.statusCount)) return;
      return sum(values(this.statusCount));
    },
  },
  watch: {
    status() {
      this.emit();
    },
  },
  mounted() {
    this.emit();
  },
  methods: {
    emit() {
      this.$emit("filter", this.status === "all" ? null : this.status);
    },
  },
};
</script>
