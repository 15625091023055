<template>
  <span>{{ countdown }}</span>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export default {
  name: "TimeCountdown",
  props: {
    datetime: {
      type: String,
      default: new Date(),
    },
    refresh: {
      type: Number,
      default: 1,
    },
    output: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    countdown: "",
    intervalId: null,
  }),
  watch: {
    datetime() {
      this.reloadTime();
    },
    output() {
      this.reloadTime();
    },
  },
  mounted() {
    this.reloadTime();
    this.intervalId = setInterval(this.reloadTime, this.refresh * 1000);
  },
  destroyed() {
    if (this.intervalId) clearInterval(this.intervalId);
  },
  methods: {
    reloadTime() {
      try {
        const diff = dayjs(this.datetime).diff(dayjs(), "minutes");
        this.countdown = diff < 0 ? 0 : diff;
      } catch (e) {
        this.countdown = 0;
      } finally {
        this.$emit("updated", this.countdown);
      }
    },
  },
};
</script>
