<template>
  <Listing />
</template>

<script>
import Orders from "@/api/orders";
import ListingDirector from "@/builders/ListingDirector";
import AudioActivationModal from "@/components/helpers/AudioActivationModal.vue";
import dayjs from "dayjs";
import { has } from "ramda";
import { mapGetters } from "vuex";
import { isNilOrEmpty } from "ramda-extension";
import { STAFF } from "@/utils/enums/roles";
import { fetchDeletedOutlets } from "@/v2/repo/fetch-deleted-outlets";

export default {
  name: "OrderHistory",
  components: {
    Listing: new ListingDirector().orderHistoryListing(),
  },
  provide() {
    return {
      getEntries: this.fetch,
      getSummary: this.summary,
    };
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      roles: "auth/getRoles",
      isSoundTested: "isSoundTested",
      outlets: "outlets/getData",
    }),
  },
  mounted() {
    if (this.roles.includes(STAFF)) {
      this.handleSoundAcceptance();
    }
  },
  methods: {
    async fetch(params) {
      params.include = "customer";

      try {
        const [orders, deletedOutlets = []] = await Promise.all([
          Orders.get(this.getParams(params)),
          fetchDeletedOutlets(this.businessId),
        ]);

        const ordersWithOutletName = orders.data.map((order) => {
          return {
            ...order,
            outletName: [...this.outlets, ...deletedOutlets].find(
              (outlet) => outlet.id === order.outlet_id
            )?.name,
          };
        });

        return { data: ordersWithOutletName, pagination: orders.pagination };
      } catch {
        // TODO: place sentry monitor here
      }
    },
    async summary(params) {
      return Orders.summaryReport(this.getParams(params));
    },
    getParams(params) {
      params.business = this.businessId;

      if (!has("createdAt", params) || isNilOrEmpty(params.createdAt)) {
        params.createdAt = [
          dayjs().startOf("day").toISOString(),
          dayjs().endOf("day").toISOString(),
        ];
      }

      return params;
    },
    handleSoundAcceptance() {
      if (this.isSoundTested) return;

      this.$modal.show(
        AudioActivationModal,
        {},
        {
          name: "audio_activation",
          width: "340px",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          resizable: true,
        }
      );
    },
  },
};
</script>
