<template>
  <div v-if="time" class="liveOrders-itemStatus">
    <div class="label">{{ label }}</div>
    <div
      class="countdown"
      :class="{
        blink: shouldBlink,
      }"
    >
      <TimeCountdown
        class="number"
        :datetime="time"
        output="minutes"
        :refresh="30"
        @updated="countdownUpdated"
      />
      <span class="min">min</span>
    </div>
  </div>
</template>

<script>
import TimeCountdown from "@/components/helpers/TimeCountdown";

export default {
  name: "Countdown",
  components: {
    TimeCountdown,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    time: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    remainingMinutes: null,
  }),
  computed: {
    shouldBlink() {
      return this.remainingMinutes <= 5;
    },
  },
  methods: {
    countdownUpdated(val) {
      this.remainingMinutes = val;
    },
  },
};
</script>
