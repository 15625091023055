<template>
  <div>
    <a
      v-if="isScheduled"
      href="#"
      class="liveOrders__item"
      :class="{
        'liveOrders__item--active': isActive,
        'liveOrders__item--warning': shouldWarn,
      }"
      data-test-item
      @click.prevent="selectOrder"
    >
      <div>
        <div class="d-flex align-items-center">
          <p class="mb-0">
            <strong>#{{ order.uid }}</strong>
          </p>
          <span class="liveOrders__time-ago">
            • <TimeAgo :datetime="order.created_at" />
          </span>
        </div>
      </div>
      <div
        v-if="order.status === 'placed'"
        class="liveOrders-itemStatus liveOrders-itemStatus--placed"
        :class="order.status"
        data-test="red-pulse"
      />
    </a>
    <a
      v-else
      href="#"
      class="liveOrders__item"
      :class="{
        'liveOrders__item--active': isActive,
        'liveOrders__item--warning': shouldWarn,
      }"
      data-test-item
      @click.prevent="selectOrder"
    >
      <div>
        <p class="mb-1">
          <strong>#{{ order.uid }}</strong>
        </p>
        <small class="d-block bold">
          {{ order.channel | cfStringBeautify }},
          <TimeAgo :datetime="order.created_at" />
        </small>
      </div>
      <div
        v-if="order.status === 'placed'"
        class="liveOrders-itemStatus liveOrders-itemStatus--placed"
        :class="order.status"
        data-test="red-pulse"
      />
      <Countdown
        v-else
        :class="order.status"
        :label="countdownLabel"
        :time="countdownTime"
        data-test="countdown"
      />
    </a>
  </div>
</template>

<script>
import TimeAgo from "@/components/helpers/TimeAgo";
import Countdown from "./Countdown";
import { path, pathOr } from "ramda";
import { mapActions, mapGetters } from "vuex";
import { ORDER_ACKNOWLEDGED } from "@/plugins/analytics/events";

export default {
  components: {
    TimeAgo,
    Countdown,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    preparationEstimatedAt() {
      const deliveryPickup = path(
        ["delivery", "pickup_estimated_at"],
        this.order
      );
      return deliveryPickup || this.order.preparation_estimated_at;
    },
    isScheduled() {
      return this.order.status === "scheduled";
    },
    shouldWarn() {
      return !this.isActive && this.order.status === "placed";
    },
    countdownLabel() {
      return this.order.status === "accepted" ? "Prep In" : "Deliver In";
    },
    countdownTime() {
      return this.order.status === "accepted"
        ? this.preparationEstimatedAt
        : this.order.delivery_estimated_at;
    },
    isActive() {
      const routeOrderId = pathOr(null, ["params", "orderId"], this.$route);
      return this.order.id === routeOrderId;
    },
  },
  methods: {
    ...mapActions({
      acknowledge: "liveOrders/markAsAcknowledged",
    }),
    markAsAcknowledged() {
      this.$analytics.trackEvent(ORDER_ACKNOWLEDGED, {
        id: this.order.id,
      });
      this.acknowledge(this.order.id);
    },
    selectOrder() {
      if (!this.order.acknowledged) {
        this.markAsAcknowledged(this.order.id);
      }

      this.$router.push({
        name: "orders.live.view",
        params: {
          orderId: this.order.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.liveOrders {
  &__time-ago {
    align-items: flex-start;
    font-size: 0.6875rem;
    margin-left: 0.25rem;
  }
}
</style>
