<template>
  <Order
    v-if="orderId"
    ref="order"
    :order-id="orderId"
    :business-id="businessId"
    class="h-100"
    @changed="changed"
    @forbidden="redirectToNextOrder"
  />
</template>

<script>
import Order from "@/modules/order-card/Index.vue";
import { mapGetters, mapActions } from "vuex";
import { pathOr } from "ramda";
import { liveStatuses } from "@/utils/enums/order-status";

export default {
  name: "LiveOrdersView",
  components: {
    Order,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      liveOrders: "liveOrders/getData",
    }),
    nextOrderId() {
      return pathOr(null, [0, "id"], this.liveOrders);
    },
  },
  methods: {
    ...mapActions({
      updateOrderStatus: "liveOrders/updateStatus",
      destroyOrder: "liveOrders/destroy",
    }),
    async changed({ id, status }) {
      this.updateOrderStatus({ id, status });

      if (liveStatuses.includes(status)) return;

      this.destroyOrder(id);
      this.redirectToNextOrder();
    },
    redirectToNextOrder() {
      const orderRoute = {
        name: "orders.live.view",
        params: {
          orderId: this.nextOrderId,
          businessId: this.businessId,
        },
      };

      const emptyRoute = {
        name: "orders.live",
        params: {
          businessId: this.businessId,
        },
      };

      this.$router.replace(this.nextOrderId ? orderRoute : emptyRoute);
    },
  },
};
</script>
