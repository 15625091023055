<template>
  <div class="p-5 text-center">
    <!-- eslint-disable -->
    <h5
      class="highlight-orders"
      v-html="$tc('message.orders_pending_warning_scheduled', scheduledOrdersAmount)"
    />
    <!-- eslint-enable -->
    <button
      type="button"
      class="btn btn-light btn-block"
      @click.prevent="closeButton"
    >
      {{ $t("label.ok") }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    scheduledOrdersAmount: {
      type: Number,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
  },

  methods: {
    closeButton() {
      this.$router.push({
        name: "orders.live.view",
        params: {
          orderId: this.orderId,
        },
      });

      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.highlight-orders {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
  text-align: center;
  flex-wrap: wrap;
  & strong {
    display: inline-block;
    min-width: 1.5625rem;
    font-size: 0.8125rem;
    color: #fff;
    line-height: 1;
    white-space: nowrap;
    margin: 0.375rem;
    padding: 0.375rem;
    border-radius: 1.875rem;
    background-color: #fc544b;
  }
}
</style>
