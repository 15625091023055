<template>
  <div class="liveOrders__list h-100">
    <div
      v-if="!orders.length || !ordersAmount"
      class="liveOrders__list--empty h-100"
      data-test="empty-list"
    >
      <CuttingBoardSVG />
      <p
        v-if="!ordersAmount"
        class="text-center text-white py-4 px-2 m-0 bold maxw-200"
        data-test="no-orders"
      >
        {{ $t("c.live_orders.there_is_no_pending_order") }}
      </p>
      <p
        v-else
        class="text-center text-white py-4 px-2 m-0 bold maxw-200"
        data-test="no-matches-for-filters"
      >
        {{ $t("c.live_orders.there_are_no_orders") }}
      </p>
    </div>
    <div v-else class="clearfix">
      <div data-test="items-list" class="overflow-auto">
        <Item
          v-for="order in orders"
          :key="order.id"
          :order="order"
          data-test="item"
        />
      </div>
    </div>
    <div class="liveOrders__refreshBtn">
      <button
        class="btn btn-primary btn-block"
        :class="{
          'btn-progress': loading,
        }"
        data-test="btn-refresh"
        @click.prevent="refresh"
      >
        <RefreshSVG class="w-10 fill_white mr-1" />
        {{ $t("label.refresh") }}
      </button>
    </div>
  </div>
</template>

<script>
import CuttingBoardSVG from "@/assets/svg/icons/cutting-board.svg";
import RefreshSVG from "@/assets/svg/icons/refresh.svg";
import Item from "./Item.vue";

export default {
  components: {
    CuttingBoardSVG,
    RefreshSVG,
    Item,
  },
  props: {
    ordersAmount: {
      type: Number,
      required: true,
    },
    orders: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>
