<template>
  <div class="liveOrders" :class="{ orderMobile: orderId }">
    <Filters @filter="(o) => (filters.status = o)" />
    <div class="liveOrders__container">
      <div class="liveOrders__listContainer scroll--dark">
        <div class="liveOrders__search">
          <input
            id="searchOrder"
            v-model="filters.search"
            class="form-control liveOrders__search--input"
            data-test-search
            type="text"
            placeholder="Search by order id..."
          />
          <a
            v-if="filters.search"
            href="#"
            data-test-clearSearch
            class="liveOrders__search--clear"
            @click="filters.search = ''"
          >
            <TimesCircleSVG class="liveOrders__search--iconClear" />
          </a>
          <label v-else class="liveOrders__search--icon" for="searchOrder">
            <SearchSVG />
          </label>
        </div>
        <List
          :orders-amount="liveOrders.length"
          :orders="orders"
          :loading="liveOrdersLoading"
          @refresh="fetchOrders"
        />
      </div>
      <div
        class="liveOrders__view"
        :class="{ 'liveOrders__view--empty': !orderId }"
        data-test="view"
      >
        <router-view :key="$route.fullPath" :order-id="orderId"></router-view>
        <p v-show="!orderId" class="h4 text-center mb-0">No order selected</p>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import Filters from "@/components/live-orders/Filter.vue";
import List from "@/components/live-orders/List.vue";
import AudioActivationModal from "@/components/helpers/AudioActivationModal.vue";
import ScheduledOrdersModal from "@/components/helpers/ScheduledOrdersModal.vue";
import SearchSVG from "@/assets/svg/icons/search.svg";
import TimesCircleSVG from "@/assets/svg/icons/times-circle.svg";
import { mapGetters, mapActions } from "vuex";
import { filter, isNil } from "ramda";
import { isNilOrEmptyString } from "ramda-extension";
import { Toast } from "@/design-system";

dayjs.extend(isSameOrAfter);

export default {
  name: "LiveOrders",
  components: {
    Filters,
    List,
    SearchSVG,
    TimesCircleSVG,
  },
  beforeRouteEnter(to, from, next) {
    document.documentElement.classList.add("block-body-live-orders");
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.documentElement.classList.remove("block-body-live-orders");
    next();
  },
  props: {
    orderId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    filters: {
      status: null,
      search: "",
    },
  }),
  computed: {
    ...mapGetters({
      liveOrders: "liveOrders/getData",
      liveOrdersLoading: "liveOrders/isLoading",
      isSoundTested: "isSoundTested",
    }),
    orders() {
      let orders = this.liveOrders;

      if (!isNil(this.filters.status)) {
        orders = filter((obj) => obj.status === this.filters.status, orders);
      }

      if (!isNilOrEmptyString(this.filters.search)) {
        const query = this.filters.search.trim().toLowerCase();
        orders = filter(
          (obj) =>
            obj.uid.toLowerCase().includes(query) ||
            obj.channel.toLowerCase().includes(query) ||
            obj.ordering_service.toLowerCase().includes(query),
          orders
        );
      }

      return orders;
    },

    scheduledOrdersActions() {
      let orders = this.liveOrders ?? [];
      let now = dayjs();

      const scheduledOrders = orders.filter(
        (order) =>
          order.status === "scheduled" && now.isSameOrAfter(order.scheduled_at)
      );

      return scheduledOrders.sort(
        (a, b) =>
          new Date(a.scheduled_at).getTime() -
          new Date(b.scheduled_at).getTime()
      );
    },
  },
  mounted() {
    this.handleSoundAcceptance();

    this.intervalToDisplayScheduleOrders = setInterval(() => {
      const order = this.scheduledOrdersActions[0];
      const scheduledOrdersAmount = this.scheduledOrdersActions.length;
      if (!scheduledOrdersAmount) {
        return;
      }

      this.$modal.show(
        ScheduledOrdersModal,
        { scheduledOrdersAmount, orderId: order.id },
        {
          name: "scheduled_orders_alert",
          width: "340px",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          resizable: true,
        }
      );
    }, 300000);
  },
  beforeDestroy() {
    clearInterval(this.intervalToDisplayScheduleOrders);
  },
  methods: {
    ...mapActions({
      fetchLiveOrders: "liveOrders/fetch",
    }),
    async fetchOrders() {
      try {
        await this.fetchLiveOrders();
      } catch (err) {
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
        throw err;
      }
    },
    handleSoundAcceptance() {
      if (this.isSoundTested) return;

      this.$modal.show(
        AudioActivationModal,
        {},
        {
          name: "audio_activation",
          width: "340px",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          resizable: true,
        }
      );
    },
  },
};
</script>
